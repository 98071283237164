import {IconButton} from '@mui/material'
import {ExpandLess, ExpandMore} from '@mui/icons-material'
import React, {useState} from 'react'
import {styled} from '@mui/system'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import colors from '../../../colors'
import FilterButton from './FilterButton'
import {SelectionArrayT} from '../../../Directory'
import {useFilterContext} from '../Filters.context'

const FilterGroup = styled('div')<{isMobile?: boolean}>(({isMobile}) => ({
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 900px)': {
    borderBottom: '1px solid #ddd',
    paddingBottom: '10px',
  },
  marginRight: 15,
  marginLeft: 15,
  maxWidth: isMobile ? '100%' : 200,
}))

const GroupTitle = styled('h3')<{isMobile?: boolean}>(({isMobile}) => ({
  fontSize: '14px',
  fontWeight: '400',
  color: colors.darkEvergreen[60],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: isMobile ? 'pointer' : 'default',
  marginBottom: '5px',
  borderBottom: `2px solid ${colors.background[5]}`,
  textTransform: 'capitalize',
}))

const FormControlRadioStyle = {
  '& .MuiTypography-root': {
    fontSize: 16,
    color: colors.darkEvergreen[80],
  },
  '& .MuiSvgIcon-root': {
    color: colors.darkEvergreen[80],
    cursor: 'pointer',
    fontSize: 14,
  },
}

type FilterGroupComponentProps = {
  isMobile: boolean
  options: SelectionArrayT[]
  title: string
  isRadio?: boolean
}

const FilterGroupComponent = ({isMobile, options, title, isRadio}: FilterGroupComponentProps) => {
  const [expanded, setExpanded] = useState<string | null>(null)
  const {setSelected, selectionArray, setSortBy, sortBy} = useFilterContext()
  const toggleExpand = (section: string) => {
    setExpanded(expanded === section ? null : section)
  }
  const toggleSelected = (opt: SelectionArrayT) => {
    setSelected((prev: SelectionArrayT[]) =>
      selectionArray.some(
          (item: { name: string; slug: string }) => item.name === opt.name && item.slug === opt.slug,
      ) ? prev.filter((item: SelectionArrayT) => item.name !== opt.name && item.slug !== opt.slug) : [...prev, opt],
    )
  }
  const handleSortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSortBy(event.target.value)
  }

  return (
    <FilterGroup isMobile={isMobile}>
      <GroupTitle onClick={() => isMobile && toggleExpand(title)} isMobile={isMobile}>
        {title}
        {isMobile && <IconButton size="small">{expanded === title.toLowerCase() ? <ExpandLess /> : <ExpandMore />}</IconButton>}
      </GroupTitle>
      {(expanded === title.toLowerCase() || !isMobile) ?
      isRadio ? (<RadioGroup value={sortBy} onChange={handleSortChange}>
          {options.map((option) => (
            <FormControlLabel key={option.name} value={option.slug} control={<Radio />} label={option.name} sx={FormControlRadioStyle}  />
          ))}
        </RadioGroup>) :
        options.map((opt) => (
          <FilterButton
            key={opt.name}
            opt={opt.name}
            onClick={() => toggleSelected(opt)}
            isSelected={selectionArray.some((item: SelectionArrayT)  => item.name === opt.name && item.slug === opt.slug)}
            containerWrapperStyle={{height: 32}}
            buttonStyle={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', fontSize: 16}}
          />
        )) : <></>}
    </FilterGroup>
  )
}

export default FilterGroupComponent
