// see: https://www.figma.com/file/gipKdGuty2YfjRbbZBjiAd/Cherub-Design-Library?type=design&node-id=59-136&mode=dev
const colors = {
  evergreen: {
    130: '#0B3932',
    100: '#0F5148',
    70: '#57857F',
    40: '#9FB9B6',
    10: '#E7EEED',
  },
  darkEvergreen: {
    100: '#0B3932',
    80: '#2f4f4f',
    75: '#486A65',
    60: '#6D8884',
    40: '#9DB0AD',
    20: '#CED7D6',
    10: '#E7EBEA',
    5: '#F3F5F5',
  },
  green: {
    130: '#53825D',
    100: '#76BA85',
    60: '#9FCFAA',
    40: '#C8E3CE',
    10: '#C8E3CE',
  },
  red: {
    130: '#DE0909',
    100: '#F85B5B',
    60: '#FB9C9C',
    40: '#FCBDBD',
    10: '#FEDEDE',
  },
  background: {
    1: '#F6ECE0',
    2: '#F6F0EA',
    3: '#E1DED1',
    4: '#E1DED1',
    5: '#C0C3B8',
    black: '#000000',
    'dark section': 'rgba(135, 193, 185, 0.10)',
    white: '#FFFFFF',
    'warm white': '#FBF7F2',
  },
  gray: {
    1: '#3A3A3A',
    1.5: '#63666A',
    2: '#808080',
    3: '#A5A5A5',
    4: '#C9C9C9',
    5: '#ECECEC',
    6: '#FAFAFA',
  },
  transparent: {
    fully: 'rgba(0, 0, 0, 0)',
    10: 'rgba(8, 67, 59, 0.10)',
  },
}

export default colors
