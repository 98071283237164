import {ProfileKind} from '../../api/types'
import {SelectionArrayT} from '../../Directory'

type Tag = {
  id: string;
  name: string;
  slug: string;
  tagCategory: {
    name: string;
    description: string;
  };
};

const copy = {
  investor: {
    'expertise-type-skill': 'Expertise Type / Skills',
    'industries': 'Interested In',
    'company-characteristics': 'Looking for',
  } as Record<string, string>,
  founder: {
    'expertise-type-skill': 'Expertise Wanted',
    'industries': 'Industries',
    'company-characteristics': 'Company Characteristics',
  } as Record<string, string>,
}

function formatTagsByCategory(tags: Tag[], type: ProfileKind) {
  return tags.reduce((acc: Record<string, SelectionArrayT[]>, tag) => {
    const category = copy[type][tag.tagCategory.name] || tag.tagCategory.description
    if (!acc[category]) {
      acc[category] = []
    }
    acc[category].push({name: tag.name, slug: tag.slug})
    return acc
  }, {})
}

export { formatTagsByCategory }
