import {CancelFill} from '../../../ui/icons'
import React from 'react'
import colors from '../../../colors'
import {styled} from '@mui/system'

const CloseIconWrapper = styled('div')<{selected?: boolean}>(({selected}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '10px',
  border: selected ? `1px solid ${colors.darkEvergreen[80]}` : '1px solid transparent',
  width: '100%',
  '&:hover': {
    cursor: 'pointer',
  },
  marginBottom: '6px',
}))

const CancelStyle = {
  justifyContent: 'space-between',
  margin: '8px',
  color: colors.darkEvergreen[100],
}

const Button = styled('button')<{selected?: boolean}>(({selected}) => ({
  padding: '6px',
  backgroundColor:  'transparent',
  color: colors.darkEvergreen[80],
  cursor: 'pointer',
  fontSize: '15px',
  textAlign: 'left',
  transition: 'background-color 0.3s',
  maxWidth: selected ? '70%' : '100%',
}))

type FilterButtonProps = {
  opt: string
  onClick: () => void
  isSelected: boolean
  style?: React.CSSProperties
  buttonStyle?: React.CSSProperties
  containerWrapperStyle?: React.CSSProperties
}

const FilterButton = ({opt, onClick, isSelected, style, buttonStyle, containerWrapperStyle}: FilterButtonProps) => (
  <CloseIconWrapper key={opt} onClick={onClick} selected={isSelected} style={{...style, ...containerWrapperStyle}}>
    <Button key={opt} selected={isSelected} style={buttonStyle}>
      {opt}
    </Button>
    {isSelected ? <CancelFill style={CancelStyle} width={20} height={20} /> : <></>}
  </CloseIconWrapper>
)


export default FilterButton
