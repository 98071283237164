import {gql, useQuery} from '@apollo/client'
import {Alert, CircularProgress, Grid, Link, Typography} from '@mui/material'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import {styled} from '@mui/material/styles'
import {Box, Stack, SxProps} from '@mui/system'
import * as Sentry from '@sentry/react'
import React, {useEffect, useRef, useState} from 'react'
import {Outlet, Link as RouterLink, useHref, useLocation, useNavigate} from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'
import {useMeQuery} from './api/types'
import {RequiresAuthentication} from './auth/RequiresAuthentication'
import colors from './colors'
import {profileViewData} from './common/helpers'
import MobileNavMenu from './profile-view/MobileNavMenu'
import {ProfileKindType} from './profile/Profile.Contexts'
import {SecondaryButton} from './ui/Buttons'
import {DealTags} from './ui/Tags'
import {LeftArrow, RightArrow} from './ui/icons'
import DirectoryFilter from './components/Filters'
import HeaderComponent from './components/Filters/header'
import {FiltersContext, useFilterContext} from './components/Filters/Filters.context'

export const route = {
  path: '/directory',
  element: (
    <RequiresAuthentication>
      <DirectoryView />
    </RequiresAuthentication>
  ),
  title: 'Directory',
  children: [
    {
      path: 'investors',
      element: <Directory profileType="investor" />,
    },
    {
      path: 'companies',
      element: <Directory profileType="founder" />,
    },
  ],
}

const tabs: SxProps = {
  display: {xs: 'none', md: 'block'},
  '& .MuiTabs-indicator': {
    backgroundColor: colors.evergreen[100],
  },
}

const tab: SxProps = {
  color: colors.darkEvergreen[40],
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '110%',
  fontStyle: 'normal',
  padding: '0 16px',
  textTransform: 'none',
  '&:visited': {
    color: colors.darkEvergreen[40],
  },
  '&.Mui-selected': {
    color: colors.evergreen[100],
    borderBottom: 0,
  },
}

const TabsContainer = styled('div')({
  display: 'contents',
})

function TabBar({onChange, dataLength, isLoading}: {onChange: () => void; dataLength: number; isLoading?: boolean}) {
  const [isMobile, setIsMobile] = useState(false)
  const location = useLocation()
  const {setShowFilters} = useFilterContext()

  const value = location.pathname === '/directory' ? '/directory/investors' : location.pathname
  const isInvestorsTab = value.indexOf('investors') > -1
  const filterRef = useRef<HTMLDivElement>(null)
  const dataLengthString = `${dataLength} ${isInvestorsTab ? 'Investor(s)' : 'Companies'}`


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (filterRef.current && !filterRef.current.contains(event.target as Node)) {
        setShowFilters(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    setShowFilters(false)
  }, [value])

  return (
    <TabsContainer ref={filterRef}>
      <Grid
        container
        justifyContent="space-between"
        item
        sx={{marginTop: '40px'}}
        style={{borderBottom: `2px solid ${colors.background[3]}`, height: 48}}>
        <Grid item>
          <Tabs
            sx={tabs}
            value={value}
            aria-label="directory selector for investors or companies"
            role="navigation"
            onChange={onChange}>
            <Tab
              sx={tab}
              value="/directory/investors"
              label="Investors"
              to="/directory/investors"
              component={RouterLink}
            />
            <Tab
              sx={tab}
              value="/directory/companies"
              label="Companies"
              to="/directory/companies"
              component={RouterLink}
            />
          </Tabs>
        </Grid>
        <Grid item>
          <HeaderComponent
            isMobile={isMobile}
            dataLength={isLoading ? 'Loading...' : dataLengthString}
          />
        </Grid>
      </Grid>
      <DirectoryFilter isMobile={isMobile} isInvestorTab={isInvestorsTab} />
    </TabsContainer>
  )
}

const CardItem = styled('div')(({theme}) => '')

function getPlaceholder(type: string, index: number) {
  const PH_INDEX = (index % 10) + 1
  if (type === 'founder') {
    return `https://assets.investwithcherub.com/img/ph/company${PH_INDEX}.png`
  } else {
    return `https://assets.investwithcherub.com/img/ph/investor${PH_INDEX}.png`
  }
}

function ProfileCard(props: any) {
  const data = profileViewData(props.profile)
  const files = props.profile.files as Record<string, string>

  const href = useHref(`/profile/${data.id}`)
  const [showHover, setShowHover] = useState(false)

  const photoKey = props.profile.profileType === 'founder' ? data.additionalPhoto?.key : data.photo?.key
  const imgUrl = (photoKey && files[`${photoKey}.directory`]) || getPlaceholder(props.profile.profileType, props.index)
  return (
    <Link
      href={href}
      sx={{textDecoration: 'none'}}
      onMouseEnter={() => setShowHover(true)}
      onMouseLeave={() => setShowHover(false)}>
      <Box sx={{width: '276px'}}>
        <Stack>
          <CardItem sx={{height: '276px', width: '276px', overflow: 'hidden'}}>
            <img height="100%" width="100%" alt={data.name} src={imgUrl} />
            {showHover && (
              <SecondaryButton
                size="medium-large"
                sx={{
                  display: {xs: 'none', md: 'block'},
                  position: 'relative',
                  top: '-158px',
                  left: '79px',
                  bgcolor: colors.background[1],
                  padding: '12px 16px 8px',
                }}>
                View profile
              </SecondaryButton>
            )}
          </CardItem>
          <CardItem sx={{marginTop: '20px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
            <Typography variant="mobileh5" sx={{color: colors.darkEvergreen[100]}}>
              {data.name}
            </Typography>
          </CardItem>
          {data.summary && (
            <CardItem
              sx={{
                color: colors.darkEvergreen[60],
                marginTop: '6px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: '276px',
                textOverflow: 'ellipsis',
              }}>
              <Typography sx={{color: colors.darkEvergreen[60]}} variant="body4">
                {data.summary?.trim()}
              </Typography>
            </CardItem>
          )}
          {data.tags && data.tags.length > 0 && (
            <CardItem sx={{marginTop: '6px'}}>
              <DealTags
                tags={data.tags}
                bgColor={colors.background[4]}
                textColor={colors.darkEvergreen[100]}
                maxRows={{
                  width: 276,
                  count: 2,
                  padding: 10 * 2 + 6, // padding left & right + marginRight
                }}
              />
            </CardItem>
          )}
        </Stack>
      </Box>
    </Link>
  )
}

const GET_DIRECTORY_PROFILES = gql`
  query GetDirectoryProfiles($page: Int!, $profileType: ProfileKind!, $tags: [String!]) {
    directory(page: $page, profileType: $profileType, tags: $tags) {
      metadata {
        totalItems
        totalPages
        previousPage
        nextPage
      }
      profiles {
        id
        data
        files
        profileType
        owner {
          id
        }
      }
    }
  }
`

const PageNavButton = styled('button')<{disabled: boolean}>(
  ({disabled}) => `
    background-color: ${colors.transparent.fully};
    border: 1px solid ${disabled ? colors.background[5] : colors.evergreen[100]};
    box-sizing: border-box;
    padding: 1px 6px;
    border-radius: 50%;
    color: ${disabled ? colors.background[5] : colors.evergreen[100]};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};

    align-items: center;
    display: flex;
    justify-content: center;
    height: 2rem;
    width: 2rem;
  `,
)

const NotFound = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  maxHeight: 400,
  marginTop: 100,
  marginBottom: 100,
  fontSize: 18,
  color: colors.darkEvergreen[80],
})

type DirectoryProps = {
  profileType: ProfileKindType
}

export type SelectionArrayT = {name: string; slug: string}

function Directory(props: DirectoryProps): React.JSX.Element {
  const navigate = useNavigate()
  const [page, setPage] = React.useState(1)
  const [selectionArray, setSelected] = useState<SelectionArrayT[]>([])
  const [sortBy, setSortBy] = useState<string>('recommended')
  const [showFilters, setShowFilters] = useState(false)

  const profileType = props.profileType
  const FILTERS_STORAGE_KEY = '@Cherub:filtersSelected'

  const {data, loading, error, fetchMore, refetch} = useQuery(GET_DIRECTORY_PROFILES, {
    variables: {page, profileType, tags: selectionArray.map(sel => sel.slug), sort: sortBy}, //eg: tags: ['tech'], sort: 'recommended'
  })

  useEffect(() => {
    const storedFilters = window.localStorage.getItem(FILTERS_STORAGE_KEY) ?? '[]'
    try {
      if (storedFilters !== '[]') {
        setSelected(JSON.parse(storedFilters))
      }
    } catch (e) {
      setSelected([])
    }
  }, [])

  useEffect(() => {
    window.localStorage.setItem(FILTERS_STORAGE_KEY, JSON.stringify(selectionArray))
    refetch()
  }, [selectionArray, sortBy])

  const fetchPage = (page: number) => {
    setPage(page)
    window.scrollTo({top: 0})
    fetchMore({variables: {page, profileType}})
  }

  const onTabChange = () => {
    window.localStorage.setItem(FILTERS_STORAGE_KEY, JSON.stringify([]))
    setSelected([])
    return setPage(1)
  }

  const hasNext = data?.directory.metadata.nextPage != null
  const hasPrevious = data?.directory.metadata.previousPage != null

  const companiesLength = data?.directory.metadata.totalItems || 0

  // only on the first time, otherwise we should show the spinner elsewhere
  if (error) {
    Sentry.captureException(error)
    return <Alert>{error.message}</Alert>
  }

  return (
    <FiltersContext.Provider value={{selectionArray, setSelected, sortBy, setSortBy, showFilters, setShowFilters}}>
      <Grid
        container
        alignItems="flex-start"
        direction="column"
        sx={{width: {xs: '100%', md: '1440px'}, padding: {xs: '1.5rem', md: '40px 120px 147px'}}}>
        <Grid item>
          <Typography variant="h1">Directory</Typography>
        </Grid>
        <Grid item>
          <Box
            sx={{left: '1.5rem', right: '1.5rem', position: 'fixed', top: '180px', display: {xs: 'block', md: 'none'}}}>
            <MobileNavMenu
              defaultValue={props.profileType === 'investor' ? 0 : 1}
              sections={[{title: 'Investors'}, {title: 'Companies'}]}
              onClick={i => {
                setPage(1)
                navigate(i === 0 ? '/directory/investors' : '/directory/companies')
              }}
            />
          </Box>
        </Grid>
        <TabBar onChange={onTabChange} dataLength={companiesLength} isLoading={loading} />
        {loading && <CircularProgress />}
        {data?.directory.metadata.totalItems > 0 ? (
          <>
            <Grid
              item
              container
              columnSpacing={4}
              rowSpacing={5}
              sx={{marginTop: {xs: '16px', md: '30px'}, paddingBottom: '16px'}}
              justifyContent={{xs: 'space-around', md: 'flex-start'}}>
              {data.directory.profiles.map((profile: any, i: number) => {
                return (
                  <Grid item key={profile.id}>
                    <ProfileCard profile={profile} index={i} />
                  </Grid>
                )
              })}
            </Grid>
            <Grid item container alignItems="center" justifyContent="center" gap={4}>
              <Grid item>
                <PageNavButton
                  onClick={() => {
                    if (hasPrevious) {
                      fetchPage(page - 1)
                    }
                  }}
                  disabled={!hasPrevious}>
                  <LeftArrow />
                </PageNavButton>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {page} / {data.directory.metadata.totalPages}
                </Typography>
              </Grid>
              <Grid item>
                <PageNavButton
                  onClick={() => {
                    if (hasNext) {
                      fetchPage(page + 1)
                    }
                  }}
                  disabled={!hasNext}>
                  <RightArrow />
                </PageNavButton>
              </Grid>
            </Grid>
          </>
        ) : (
          <NotFound>No profiles found</NotFound>
        )}
      </Grid>
    </FiltersContext.Provider>
  )
}

export default function DirectoryView(): React.JSX.Element {
  const {data} = useMeQuery()
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    if (location.pathname !== '/directory') {
      return
    }

    if (data?.me.profileType === 'founder') {
      navigate('/directory/investors', {replace: true})
    } else {
      navigate('/directory/companies', {replace: true})
    }
  }, [location.pathname, navigate, data?.me.profileType])

  return (
    <Box>
      <Header />
      <Stack alignItems="center">
        <Outlet />
        <Footer />
      </Stack>
    </Box>
  )
}
