import React from 'react'
import {styled} from '@mui/system'
import {Button} from '@mui/material'
import {ExpandLess, ExpandMore} from '@mui/icons-material'
import colors from '../../../colors'
// import { Search as SearchIcon } from '@mui/icons-material'
import {useFilterContext} from '../Filters.context'

const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: -8,
})

export const SearchInput = styled('input')(
  () => `
  background: ${colors.transparent.fully};
  box-sizing: border-box;
  color: ${colors.darkEvergreen[100]};
  display: block;
  flex: 1 0 0;
  font-family: "Warnock-Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 23px;
  letter-spacing: -0.015rem;
  width: 325px;
  border: 1px solid ${colors.background[5]};
  border-radius: 40px;
  height: 40px;
  padding: 10px 20px 8px 20px;
  outline: none;
  ::placeholder {
    color: ${colors.darkEvergreen[40]};
  }
`,
)

export const SearchContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  paddingBottom: 0,
})

const SearchIconWrapper = styled('div')({
  position: 'absolute',
  right: '15px',
  top: '50%',
  transform: 'translateY(-50%)',
  color: colors.darkEvergreen[40],
  pointerEvents: 'none',
})

const MainContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginBottom: '12px',
})

const ButtonStyle = (showFilters: boolean) => ({
  borderRadius: '40px',
  border: `1px solid ${showFilters ? colors.darkEvergreen[100] : colors.background[5]}`,
  color: colors.evergreen[100],
  padding: '13px 20px',
  textTransform: 'capitalize',
  fontSize: '16px',
  height: 44,
} as React.CSSProperties)

const Badge = styled('div')({
  position: 'absolute',
  top: '-5px',
  right: '-5px',
  width: '20px',
  height: '20px',
  backgroundColor: '#2f4f4f',
  color: '#fff',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '12px',
  fontWeight: 'bold',
})

type HeaderComponentProps = {
  isMobile: boolean
  dataLength: string
}

const HeaderComponent = ({isMobile, dataLength}: HeaderComponentProps) => {
  // const [searchQuery, setSearchQuery] = useState('')

  const {setShowFilters, showFilters, selectionArray} = useFilterContext()

  const selectedFilters = selectionArray?.length ?? 0
  return (
    <Header>
      <MainContainer>
        {/*<SearchContainer>*/}
        {/*  <SearchInput placeholder="Search..." value={searchQuery} onChange={e => setSearchQuery(e.target.value)} />*/}
        {/*  <SearchIconWrapper>*/}
        {/*    <SearchIcon />*/}
        {/*  </SearchIconWrapper>*/}
        {/*</SearchContainer>*/}
        {!isMobile && (
          <Button
            variant="outlined"
            style={ButtonStyle(showFilters)}
            onClick={() => setShowFilters(!showFilters)}
            endIcon={showFilters ? <ExpandLess /> : <ExpandMore />}
          >
            Filter & Sort
            {selectedFilters > 0 && <Badge>{selectedFilters}</Badge>}
          </Button>
        )}

        <span style={{marginLeft: 10, color: colors.evergreen[100]}}>{dataLength}</span>
      </MainContainer>
    </Header>
  )
}

export default HeaderComponent
